import { useState, useEffect, useCallback } from 'react'
import qs from 'query-string'

// Variables that hold the Gatsby location property and its children props, so this can be used in SSR
export let locationPathname =
  typeof window !== 'undefined' ? window.location.pathname : ''
export let locationProtocol =
  typeof window !== 'undefined' ? window.location.protocol : ''
export let locationHost =
  typeof window !== 'undefined' ? window.location.host : ''
export let locationSearchParams =
  typeof window !== 'undefined' ? window.location.search : ''

export function useSearchParams(link, orgId, payerLink) {
  const [url, setUrl] = useState('')
  let isOrgIdDefined = orgId !== undefined && orgId !== null

  const getLocalizationFromPathname = () => {
    const localization = locationPathname.split('/')[1] // Extract the first segment after the initial forward slash

    // Check if the localization value is 'en' or 'es'
    return localization === 'es' ? `${localization}-US` : ''
  }

  useEffect(() => {
    if (!link) return

    // Extract URL search parameters and convert them to lowercase
    const urlSearchParams = new URLSearchParams(locationSearchParams)
    const lowerCaseParams = new URLSearchParams(
      [...urlSearchParams].map(([key, value]) => [key.toLowerCase(), value])
    )

    // Retrieve and remove orgId and patientId query params
    const searchParamOrgId = lowerCaseParams.get('orgid')
    const searchParamPatientId = lowerCaseParams.get('patientid')
    lowerCaseParams.delete('orgid')
    lowerCaseParams.delete('patientid')

    // Prepare the patientId query parameter based on searchParamPatientId
    const setPatientIdParam = searchParamPatientId
      ? `patientId=${encodeURIComponent(searchParamPatientId)}`
      : ''

    // Determine the orgId argument to use, considering isOrgIdDefined
    let isSearchParamOrgIdDefined =
      searchParamOrgId !== undefined && searchParamOrgId !== null
    const setOrgIdArg = isOrgIdDefined ? orgId : ''

    // Select the orgId value based on searchParamOrgId or setOrgIdArg, and convert to uppercase
    const selectOrgId = isSearchParamOrgIdDefined
      ? searchParamOrgId?.toUpperCase()
      : setOrgIdArg?.toUpperCase()

    // Prepare the orgId query parameter based on the selected orgId
    const setOrgIdParam =
      isSearchParamOrgIdDefined || isOrgIdDefined
        ? `orgId=${encodeURIComponent(selectOrgId)}`
        : ''

    // Convert lowerCaseParams to an array of query parameter strings
    const lowerCaseQueryParamsArray = []
    lowerCaseParams.forEach((value, key) => {
      lowerCaseQueryParamsArray.push(`${key}=${value}`)
    })

    // Combine lowerCaseQueryParamsArray with setPatientIdParam and setOrgIdParam
    const optionalQueryParams = [setPatientIdParam, setOrgIdParam].filter(
      param => param !== ''
    )
    const queryParamsArray = [
      ...lowerCaseQueryParamsArray,
      ...optionalQueryParams,
    ]

    // Construct the final URLSearchParams object
    const newURLSearchParams = new URLSearchParams()
    let hasValidParams = false

    queryParamsArray.forEach(param => {
      const [key, value] = param.split('=')

      // Check if the parameter format is valid
      if (
        param.split('=').length !== 2 || // Check if the parameter has exactly one '=' sign
        param.split('=')[0] === undefined || // Check if the key part is undefined
        param.split('=')[1] === undefined // Check if the value part is undefined
      ) {
        // Error handling for invalid query parameter format
        console.error(`Invalid query parameter format: ${param}`)
        return // Exit the loop if the format is invalid
      }

      hasValidParams = true
      // Append the valid parameter to the new URLSearchParams object
      newURLSearchParams.append(key, value)
    })

    // Construct the final query parameter string with a leading '?' if necessary
    const setQueryParams = hasValidParams
      ? '?' + newURLSearchParams.toString()
      : ''

    // Prepare the URL by adding payerLink and formatting the link
    const stringifyLink = link.toString().replace(/\/+$/, '')
    const strippedLink = stringifyLink.replace('https://', '')
    const formattedLink = stringifyLink.includes('https://')
      ? strippedLink
      : link

    // Remove one or more leading forward slashes
    const localization = getLocalizationFromPathname().replace(/^\/+/, '')
    const queryParams = setQueryParams.replace(/^\/+/, '')

    const formattedUrl = `https://${
      payerLink ? `${payerLink}.` : ''
    }${formattedLink}/${localization}${queryParams}`

    // TODO: Refactor this bit of logic once env has been set at the Contentful level
    if (process.env.ENV === 'dev' || locationHost.includes('dev')) {
      setUrl(
        `https://${'soleraconnect-dev.azurewebsites.net'}/${getLocalizationFromPathname()}${setQueryParams}`
      )
    } else if (
      (locationHost.includes('wellvolution') || locationHost.includes('bsc')) &&
      locationHost.includes('int')
    ) {
      setUrl(
        `https://${'bsc-int.gosolera.com'}/${getLocalizationFromPathname()}${setQueryParams}`
      )
    } else if (locationHost.includes('int')) {
      setUrl(
        `https://${'soleraconnect-int.azurewebsites.net'}/${getLocalizationFromPathname()}${setQueryParams}`
      )
    } else {
      setUrl(formattedUrl)
    }
  }, [link, orgId, payerLink])

  return url
}

const setQueryStringWithoutReload = queryString => {
  const newUrl = `${locationProtocol}//${locationHost}${locationPathname}${
    queryString ? `?${queryString}` : ''
  }`

  window.history.pushState({ path: newUrl }, '', newUrl)
}

const getQueryStringValue = (key, queryString = locationSearchParams) => {
  const values = qs.parse(queryString)
  return values[key]
}

const setQueryStringValue = (
  key,
  value,
  queryString = locationSearchParams
) => {
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({
    ...values,
    [key]: value,
  })
  setQueryStringWithoutReload(`${newQsValue}`)
}

export function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue)
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue)
      setQueryStringValue(key, newValue)
    },
    [key]
  )

  return [value, onSetValue]
}

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { css } from '@emotion/react'

/** TODO: Refactor this into /libs/ */
export const mapSubModuleFields = props => {
  props.subModules &&
    props.subModules.forEach(item => {
      if (item.image) {
        item.imageUrl = item.image.file.url
      }
      if (item.logo) {
        item.logoUrl = item.logo.file.url
      }
      if (item.richText && item.richText.raw) {
        item.richText = documentToReactComponents(JSON.parse(item.richText.raw))
      }
    })

  return props
}

export const textStyle = textColor =>
  textColor &&
  css`
    color: ${textColor} !important;
  `
export const backgroundStyle = backgroundColor =>
  backgroundColor &&
  css`
    background-color: ${backgroundColor} !important;
  `
export const buttonStyle = (buttonColor, buttonTextColor) => css`
  ${buttonTextColor && `color: ${buttonTextColor} !important`};
  ${buttonColor && `background-color: ${buttonColor} !important}`};
`
export const containerImageCover = (imageUrl, backgroundPosition) => css`
  ${imageUrl && `background-image: url(${imageUrl})`};
  ${backgroundPosition && `background-position: ${backgroundPosition}`};
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;
`

export const backgroundPosition = backgroundPosition => css`
  ${backgroundPosition &&
  `background-position: ${
    backgroundPosition === 'center' ? '50% 10%' : backgroundPosition
  }`};
`

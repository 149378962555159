import React from 'react'
import { Image } from 'react-bootstrap'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

export const MappedImage = ({ image, options, style }) => {
  let component

  if (image?.gatsbyImageData) {
    component = (
      <GatsbyImage
        {...options}
        image={getImage(image)}
        alt={image?.title}
        style={style}
      />
    )
  } else {
    component = (
      <Image
        {...options}
        src={image?.file.url}
        alt={image?.title}
        style={style}
      />
    )
  }

  return component
}
